// NORWEGIAN (nb_NO)

export default {
  // START Aria translation
  // aria_enter: 'Beginning donation section',
  // aria_flow:
  // 'Starting {{routeName}} section. Section {{sectionIndex}} of {{totalSteps}}',
  input_amount_field_name: 'Felt for inndatabeløp',
  aria_input_amount_field:
    'Inndatafelt: Angi egendefinert donasjonsbeløp (til nærmeste dollar)',
  aria_amount_summary: 'Oppsummering av ordrebeløp',
  aria_member_summary: 'Oppsummering av medlemsbeløp',
  aria_help_icon: 'Informasjon/hjelp-ikon',
  aria_required: 'Obligatorisk',
  aria_designation_button: 'Utpeke til',
  aria_designation_selection_label: 'Velg en annen betegnelse',
  aria_year: '2-sifret utløpsår',
  aria_month: '2-sifret utløpsmåned',
  aria_security_code:
    'Kredittkort sikkerhetskode. 3- eller 4-sifret kode på baksiden av kortet ditt',
  aria_unexpected_error_link:
    'Vennligst klikk her og kontakt vårt donorserviceteam umiddelbart.',
  // END Aria translation
  internatl_error_500:
    '500 - Intern serverfeil oppstod. Vennligst kontakt teamet vårt umiddelbart',
  stripe_promise_error: `Kunne ikke laste inn Stripe Promise`,
  loading: 'Laster inn ...',
  loading_image: 'Laster inn bilde ...',
  verification: 'Verifisering pågår...',
  timeout: 'Det har oppstått en tidsavbruddsfeil. Prøv igjen senere.',
  company: 'selskapsnavn',
  company_valication: 'Vennligst skriv inn firmanavnet',
  company_help_text:
    'Firma- eller organisasjonsnavnet som vil vises på bekreftelsen og / eller kvitteringen.',
  employer: 'Min arbeidsgiver',
  salutation: 'Hilsen',
  first_name: 'Fornavn',
  first_name_validation: 'Vennligst skriv inn fornavnet ditt',
  last_name: 'Etternavn',
  last_name_validation: 'Vennligst fyll inn ditt etternavn',
  email: 'E-post',
  email_validation: 'Vennligst skriv inn en gyldig e-post adresse',
  email_help_text:
    'Bekreftelsen og / eller skattekvitteringen vil bli sendt her.',
  phone: 'Telefon',
  phone_validation:
    'Angi et gyldig telefonnummer uten desimaler eller bindestreker',
  address: 'Adresse',
  address2: 'Adresse 2',
  address_validation: 'Vennligst skriv inn adressen din',
  suite: 'Suite',
  city: 'By',
  city_validation: 'Vennligst skriv inn byen din',
  province: 'Provins',
  province_validation: 'Vennligst skriv inn provinsen din',
  postal_code: 'postnummer',
  postal_code_validation: 'Vennligst skriv inn et gyldig postnummer',
  country: 'Land',
  country_validation: 'Angi et gyldig land',
  amount_raised: 'Beløp hevet',
  goal: 'Mål',
  dedication_checkbox: 'Ja, donasjonen min er til ære eller til minne om noen',
  honour_dedication: 'Til ære for...',
  memory_dedication: 'Til minne om...',
  show_honour_message:
    'Du har antydet at du gir denne donasjonen til ære for noen',
  show_memory_message:
    'Du har antydet at du gir denne donasjonen til minne om noen',
  honouree_label: 'Honoree navn',
  honouree_validation: 'Vennligst skriv inn et gyldig hedersnavn',
  recipient_email: 'Vennligst skriv inn mottakerens e-postadresse',
  special_message: 'Del din spesielle melding her. (Valgfri)',
  acknowledgment_checkbox: 'Ja, jeg vil gjerne sende en melding om gaven min',
  recipient_email_validation:
    'Skriv inn mottakerens e -postadresse eller fysiske adresse',
  special_message_validation: 'Vennligst skriv inn en melding til mottakeren',
  employer_matches_checkbox: 'Arbeidsgiveren min vil matche donasjoner',
  anonymousHelptext:
    'Dette vil skjule navn og donorinformasjon fra all offentlig kommunikasjon; imidlertid vil veldedighetsorganisasjonen fremdeles motta informasjonen din.',
  giftAid_checkbox:
    'Ja, jeg vil gjerne legge til et gavehjelp på <1>{{gift}}</1> (25% av donasjonen) på toppen av <2>{{donation}}</2> -donasjonen min.',
  month: 'Måned',
  year: 'År',
  privacy_policy: 'Personvernregler',
  terms_conditions: 'Vilkår og betingelser',
  minimum_amount: 'Minimumsbeløp',
  donate_btn: 'Donere',
  start_p2p_btn: 'Start innsamling',
  next_btn: 'Neste',
  prev_btn: 'Tidligere',
  thank_you: 'Takk, {{name}}!',
  donation_received: 'Donasjonen din er mottatt.',
  confirmationDetails_thank_you: 'Takk, {{name}}',
  confirmationDetails_donation_received:
    'Donasjonen din av <1>{{donationAmount}}</1> er mottatt.',
  confirmationDetails_receipt:
    'En skattekvittering vil bli sendt til <em>{{email}}</em>.',
  donate_once: 'Doner\nen gang',
  donate_monthly: 'Doner\nmånedlig',
  donate_annually: 'Doner\nårlig',
  finish_btn: 'Bli ferdig',
  cancel_btn: 'Avbryt',
  redirectMessage: 'Du blir omdirigert i:',
  seconds: 'sekunder',
  processing: 'Behandler donasjonen din ...',
  processingEP: 'Behandler registreringen ...',
  processingCD: 'Oppdaterer kontaktinformasjonen din ...',
  processingPaymentSchedule: 'Oppdaterer betalingsinformasjonen din ...',
  card_number: 'Kortnummer',
  card_validation: 'Vennligst oppgi et gyldig kredittkortnummer',
  expiry_date_mm: 'MM',
  expiry_date_yy: 'ÅÅ',
  expiry_date_mmyy: 'MM/ÅÅ',
  expiry_date_mm_validation:
    'Angi en gyldig utløpsdato (MM). Det første sifferet er 0 for ensifrede måneder.',
  expiry_date_yy_validation: 'Angi en gyldig utløpsdato (ÅÅ).',
  card_security_code: 'CVV',
  card_security_code_validation: 'Vennligst skriv inn CVV',
  giving: 'Gi <1>{{currency}}</1>',
  share_btn: 'Dele',
  share_on: 'Dele på',
  now_booking: 'Nå bestiller',
  book_tickets: 'Bestill billetter',
  use_existing_credit_card: 'Bruk eksisterende kredittkort',
  select: 'Å velge',
  card: 'Kort',
  next: 'Neste',
  last: 'Siste',
  cardType: 'Kort type',
  expiry: 'Utløp',
  apple_pay: 'Apple Pay',
  google_pay: 'Google Pay',
  donate: 'Donere',
  of_goal: 'av mål',
  supporters: 'Tilhengere',
  days_left: 'Dager igjen',
  minimum_donation_of: 'Minimum donasjon av',
  top_fundraising_teams: 'Topp innsamlingslag',
  top_individual_fundraisers: 'Topp individuelle innsamlere',
  individual_subtitle: 'Start din individuelle innsamling i dag!',
  team_subtitle: 'Start teaminnsamlingen din i dag!',
  campaign_title: 'La oss gå!',
  individual_fundraiser_btn: 'Opprett individuell side',
  team_fundraiser_btn: 'Opprett lagside',
  individual_fundraiser_tab: 'Individuelle sider',
  team_fundraiser_tab: 'Lagsider',
  campaign_name: 'Fundraising sidenavn',
  team_campaign_name: 'Team Fundraising Name',
  campaign_name_validation: 'Angi et gyldig navn for innsamling av kampanjer',
  campaign_goal: 'Fundraising Goal',
  team_campaign_goal: 'Team Fundraising Goal',
  campaign_goal_validation: 'Angi et gyldig innsamlingsmål',
  campaign_url: 'Kampanje-URL',
  start_date: 'Startdato',
  end_date: 'Sluttdato',
  create_campaign_btn: 'Opprette innsamlingsside',
  update_campaign_btn: 'Oppdater innsamlingssiden',
  delete_campaign_btn: 'Slett denne siden',
  cancel_changes_btn: 'Avbryt endringer',
  password: 'Passord',
  password_validation: 'Vennligst skriv inn passordet ditt',
  password_requirements:
    'Passordet må inneholde minst 8 tegn, ett stort, ett nummer og ett spesialtegn',
  sign_in: 'Logg inn',
  forgot_password: 'Glemt passord?',
  sign_up: 'Melde deg på',
  dont_have: 'Har du ikke en konto?',
  already_have: 'Har du allerede en konto?',
  go_back: 'Gå tilbake',
  forgot_password_title: 'Tilbakestille passord',
  forgot_password_btn: 'Send instruksjoner for tilbakestilling',
  download_media_kit: 'Last ned mediesettet',
  learn_to_fundraise: 'Lær å samle inn penger',
  help_center: 'Hjelpesenter',
  days_left_to_donate: 'Dager igjen å donere',
  days_left_to_fundraise: 'Dager igjen til innsamling',
  quick_links: 'Hurtigkoblinger',
  edit_campaign_btn: 'Redigere',
  view_campaign_btn: 'Utsikt',
  share_my_page_btn: 'Dele',
  team_campaigns: 'Lagkampanjer',
  your_campaigns: 'Dine innsamlingssider',
  activity: 'Aktivitet',
  settings: 'Innstillinger',
  birthday: 'Bursdag (valgfritt)',
  save_changes: 'Lagre endringer',
  edit_profile_title: 'Rediger / oppdater profil',
  profile_photo: 'Profilbilde',
  upload_photo: 'Last opp bilde',
  manage_prefs_title: 'Administrer innstillinger',
  donation_updates: 'Donasjonsoppdateringer og kommende arrangementer',
  phone_campaigns: 'Telefonkampanjer',
  updates_via_mail: 'Oppdateringer via e-post',
  old_password: 'gammelt passord',
  new_password: 'Nytt passord',
  old_password_validation: 'Vennligst skriv inn ditt gamle passord',
  new_password_validation: 'Vennligst skriv inn ditt nye passord',
  change_password_title: 'Bytt passord',
  edit_profile: 'Rediger profil',
  my_profile: 'Min profil',
  edit_campaign: 'Rediger kampanje',
  log_out: 'Logg ut',
  start_a_campaign_btn: 'Start en kampanje',
  campaign_photo: 'Fundraising bakgrunnsbilde',
  recommended_photo_size: 'Anbefalt bildestørrelse: 1240 x 600 piksler',
  mission_statement: 'Misjonserklæring',
  characters_remaining: '{{count}} tegn igjen',
  edit_campaign_title_individual: 'Rediger din individuelle innsamlingsside',
  edit_campaign_title_team: 'Rediger teamets innsamlingsside',
  edit_campaign_title_team_member: 'Rediger innsamlingssiden for teammedlemmet',
  deletion_confirmation_question:
    'Er du sikker på at du vil slette denne innsamlingssiden?',
  join_team_btn: 'Bli med i teamet',
  join_team_subtitle: 'Bli med på {{name}}',
  donated_by: 'Donert av',
  campaign_supporters: 'Kampanjesupportere',
  display_name:
    'Skriv inn et visningsnavn her - det vil vises på innsamlingssiden',
  leave_message:
    'Del meldingen din om støtte - dette vil vises på organisasjonen eller innsamlingssiden, slik at de vet at de mottok en gave fra deg (valgfritt)',
  support_help_text: "Meldinger uten navn vises som 'Donert av anonym'",
  hide_name_checkbox: 'Ikke vis navnet mitt på Kampanjesiden',
  show_support_title: 'Del en melding om støtte',
  show_support_subtitle: 'La innsamlere vite hva du ga og hvorfor.',
  creating_campaign: 'Opprette kampanjen ...',
  failed_campaign_creation: 'Kunne ikke opprette kampanjen',
  join_team_checkbox: 'La andre bli med på teamet ditt',
  credentials_error: 'Ugyldig e-postadresse eller passord',
  email_exists: 'Denne epostadressen er allerede i bruk',
  about_campaign: 'Om kampanjen',
  teams: 'Lag',
  team_members: 'Lag medlemmer',
  participants: 'Deltakere',
  anonymous: 'Anonym',
  fundraising_team_for: 'Innsamlingsteam for:',
  fundraising_for: 'Innsamling av:',
  fundraising_team_member: 'Medlem av:',
  no_team_members: 'Ingen har meldt seg på som medlem av dette teamet.',
  fundraising_dates: 'Innsamlingsdatoer',
  return_back_btn: 'Gå tilbake til profilen',
  changes_saved_btn: 'Endringer lagret!',
  no_changes_btn: 'Ingen endringer å lagre',
  password_reset_success:
    'Instruksjoner for tilbakestilling av passord er sendt til {{email}}.',
  total_amount_raised: 'Totalt samlet beløp',
  amount: '<1>{{currencySymbol}}{{amount}}</1>',
  individual_member_of: 'Enkeltmedlem i:',
  team_member_of: 'Teammedlem av:',
  role_team_member: '(Roll: Team Member)',
  role_team_captain: '(Roll: Lagkaptein)',
  no_individual_pages: 'Du har ikke noen individuelle innsamlingssider.',
  no_team_pages: 'Du har ingen sider for innsamling av team.',
  no_pages_to_display: 'Ingen innsamlingssider å vise.',
  join_as_individual_btn: 'Bli med som individ',
  create_a_team_btn: 'Lag et team',
  expired: 'utløpt',
  share_on_facebook: 'Del på Facebook',
  share_on_twitter: 'Del på Twitter',
  share_on_linkedin: 'Del på LinkedIn',
  failure_message: 'Kunne ikke behandle betalingen',
  search: 'Søk',
  constituentNumber: 'Konstituerende nummer',
  contact_not_found: 'Bestanddel ikke funnet',
  failed_password_change: 'Gammelt passord samsvarer ikke',
  success_password_change: 'Passordet er oppdatert',
  lifetimeGiving: 'Livstid',
  givingHistory: 'Gi historie',
  thisYearGiving: 'I år',
  lastGiftAmount: 'Siste gavebeløp',
  recurringGift: 'Gjentakelse',
  creditCard: 'Kredittkort',
  childRecurringGift: 'Barnegående status',
  membership: 'Medlemskap',
  fetching_pages: 'Henter innsamlingssider ...',
  title_404: '404',
  subtitle_404: 'Beklager, vi fant ikke siden din.',
  message_404: 'Ta meg tilbake til -',
  home_page: 'Hjemmeside',
  update_contact_info: 'Oppdater kontaktinformasjon',
  update_payment_info: 'Oppdater betalingsinfo',
  update_payment_schedule: 'Oppdater betalingsplan',
  update: 'Oppdater',
  update_saved_message: 'Oppdateringen ble lagret',
  new_gift_btn: 'Ny gave',
  new_gift_title: 'Lage en ny gave',
  create_new_contact_btn: 'Lag gave til ny giver',
  cancel_donation: 'Avbryt donasjonen',
  back: 'Tilbake',
  start_by: 'Start med å skrive inn en',
  or: 'Eller',
  FeltPressure: 'Donor følte press fra innsamling',
  NoLongerAfford: 'Har ikke lenger råd',
  UnderMinimumWage: 'Under aldersgrensen',
  SpouseObjected: 'Ektefellen protesterte',
  ChangedMind: 'Skiftet sinn',
  IntendedShortTerm: 'Donor beregnet på kortvarig donasjon',
  IntendedOneTime: 'Donor hadde til hensikt en engangs donasjon',
  Deceased: 'Avdød',
  Misunderstood: 'Misforstått engasjement',
  OtherCharities: 'Donere til andre veldedige organisasjoner',
  PaymentDeclined: 'Betaling avvist',
  Complaint: 'Klage',
  AccountClosed: 'Kontoen er lukket',
  AlreadyDonating: 'Donerer allerede',
  NoReason: 'Ingen grunn oppgitt',
  continue_session: 'Hold meg innlogget',
  session_timeout: 'Session Timeout',
  session_message: 'Gjeldende økt utløper om <1>{{countdown}}</1> sekunder.',
  continue_confirmation: 'Vil du fortsette?',
  master_donate_btn: 'Doner til denne kampanjen',
  team_donate_btn: 'Doner til teamet',
  team_member_donate_btn: 'Doner til {{name}} Fundraiser',
  note: 'Merk:',
  bg_image_note:
    'Dette bildet vises som bakgrunn på innsamlingssidene. Den er delvis dekket av Fundraising Stats Component (Box) på skjermbilder på skrivebordet, og rundt 90% dekket på mindre skjermstørrelser.',
  sample_campaign_stats:
    'Dette er et eksempel på innsamlingsstatistikkomponent for å demonstrere hvordan bakgrunnsbildet vil vises på en innsamlingsside.',
  preview: 'Forhåndsvisning',
  donation_through: 'Donasjon ble gitt gjennom',
  donation: 'Donasjon',
  view_fundraising_page: 'Se innsamlingssiden',
  how_often_donate: 'Hvor ofte vil du donere?',
  select_amount_donate: 'Velg beløpet du vil gi:',
  direct_your_gift: 'Hvordan vil du lede gaven din?',
  once: 'En gang',
  monthly: 'Månedlig',
  annually: 'Årlig',
  why_donate_monthly: 'Hvilken innvirkning har en månedlig donasjon?',
  eft: 'EFT',
  bank: 'Bank',
  bank_check: 'Bank sjekk',
  bank_name: 'Bank navn',
  acc_no: 'Kontonummer',
  routing_no: 'Transitt- og finansinstitusjonsnummer (8 sifre)',
  chequing: 'chequing',
  savings: 'besparelser',
  select_acc_type: 'Velg Kontotype',
  acc_no_validation: 'Kontonummer kreves',
  routing_no_validation: 'Transitt- og finansinstitusjonsnummer kreves',
  bank_name_validation: 'Banknavn er påkrevd',
  Completed: 'Fullført',
  Terminated: 'avbrutt',
  Failed: 'Mislyktes',
  TransactionRefund: 'Refusjon',
  InProgress: 'I prosess',
  notPaymentScheduleMessage: 'Ingen betalingsplan tilgjengelig',
  notPaymentMethodsMessage: 'Ingen betalingsmåte tilgjengelig',
  Amount: 'Beløp',
  Name: 'Navn',
  Team: 'Team',
  gift_aid: 'Gavehjelp',
  donor_details: 'Donordetaljer',
  payment_details: 'Betalingsinformasjon',
  Total: 'Total',
  other_btn: 'Annen',
  anonymous_checkbox: 'Gjør donasjonen min anonym',
  your_donation: 'Donasjonen din <1>{{currency}}</1>',
  your_monthly_donation: 'Uw maandelijkse donatie <1>{{currency}}</1>',
  your_annually_donation: 'Din årlige donasjon <1>{{currency}}</1>',
  your_membership: 'Ditt medlemskap <1>{{currency}}</1>',
  include_membership_checkbox:
    'Inkluder medlemskapet mitt i <1>{{donation}}</1> <2>{{donationFrequency}}</2> -donasjonen min.',
  share_generosity: 'Del din raushet og inspirer andre:',
  help_further: 'Vil du hjelpe videre?',
  get_involved_title: 'Bli involvert',
  set_up_fundraising_btn: 'Sett opp min innsamlingsaksjon',
  start_fundraising_accordian_title: 'Støtt en kampanje',
  donateMonthlyLink: 'Jeg vil donere månedlig',
  donateAnnuallyLink: 'Jeg vil donere årlig',
  enter_amount: 'Vennligst skriv inn beløp',
  selection_label: 'Annet',
  order_label: 'Vennligst velg',
  membership_order_label: 'Velg (valgfritt)',
  support_display_all: 'Vennligst vis navn, melding og donasjonsbeløp.',
  support_hide_amount:
    'Vennligst vis bare navnet mitt og meldingen (skjuler donasjonsbeløpet).',
  support_hide_name:
    'Vennligst vis bare donasjonsbeløpet og meldingen (skjuler navnet).',
  support_hide_all: 'Nei, skjul navn, melding og donasjonsbeløp.',
  greatest_need: 'Største behov',
  background_image_alt_text: 'dynamisk bakgrunns tema bilde',
  summary_link_description: `Klikk på 'Din donasjon' for å endre gavebeløpet`,
  unexpected_error_message:
    'Det har oppstått en feil. Men transaksjonen din er behandlet og vil vises på kontoutskriften din. <1>Ikke prøv igjen.</1><br /><br /><2>Klikk her</2> eller kontakt vårt givertjenesteteam umiddelbart for å informere om at du har mottatt denne meldingen.',
  applePay_error: `Apple Pay er bare tilgjengelig når den er aktivert med iOS -kompatible enheter (iPhone/iPad/Mac).`,
  googlePay_error:
    'GooglePay er ikke aktivert og/eller tilgjengelig på denne enheten.',
  additionalInfo: 'Tilleggsinformasjon',
  additional_info_msg:
    'Hvis du har kommentarer knyttet til donasjonen din, vennligst inkluder dem her.',
  notes_or_direction: 'Notater eller retning',
  readMore: 'Les mer',
  showLess: 'Vis mindre',
  isCompanyGift: 'Er dette en bedriftsdonasjon',
  yes: 'Ja',
  no: 'Nei',
  tribute_type: 'Vil du at vi sender et kort?',
  no_sendTribute: 'Nei\ntakk',
  email_sendTribute: 'Ja,\npå e-post',
  mail_sendTribute: 'Ja,\npå mail',
  addressee_name: 'Send kort til',
  addressee_name_validation: `Vennligst skriv inn mottakerens navn`,
  required_pwd: 'Vennligst skriv inn passordet ditt',
  pwd_match:
    'Må inneholde 8 tegn, en stor bokstav, en liten bokstav, ett tall og ett spesialtegn',
  MissionCRM_label: 'Mission CRM-etikett',
  recaptcha_token_error:
    'Kunne ikke hente reCAPTCHA-token på dette tidspunktet. Vennligst prøv igjen senere.',
  recaptcha_failure:
    'Dårlig forespørsel, dårlig recaptcha-token. Dobbeltsjekk informasjonen din og prøv igjen.',
  required_message: 'Svar på spørsmålene nedenfor kreves.',
  custom_questions_error: 'Svar på alle spørsmål kreves',
};
