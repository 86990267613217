import * as React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'react-jss';

import { TransactionCurrency } from '@app/App';
import { RootState } from '@app/RootState';
import loadTheme from '@styles/loadTheme';
import { DonationPageType, PageKind } from '@root/enums';
import {
  setSelectedNewGift,
  setContactSignUp,
  setPaymentNotice,
  fetchCustomQuestions,
} from '@app/app.slice';
import OCPLayout from '@components/layouts/ocpLayout';
import DonationLayout from '@components/layouts/donationLayout';
import { DonationLayoutSkeleton } from '@components/layouts/donationLayout/donationLayout.skeleton';
import { defaultTheme } from '@styles/themes';
import i18n from '@root/i18n';
import TagManager from 'react-gtm-module';
import { fetchDonationPageDetails } from './donation.slice';
import useStyles from './donation.style';

export const BASE_PATH = '/donation/:friendlyUrl';

interface Props {
  donationPageType: DonationPageType;
}

const DonationModule: React.FC<Props> = ({ donationPageType }) => {
  const [theme, setTheme] = React.useState(defaultTheme);
  const { friendlyUrl } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  // Getting pageDetails from redux
  const { donationPageDetails, defaultDesignation, isLaoding } = useSelector(
    (state: RootState) => state.donation
  );

  const { contactDetails, clientCode, selectedNewGift, gtmId } = useSelector(
    (state: RootState) => state.app
  );

  const [isOCPGate, setIsOCPGate] = React.useState(false);

  const [donationPageTypeId, setPageType] =
    React.useState<DonationPageType>(donationPageType);

  React.useEffect(() => {
    const defaultRouteName =
      donationPageTypeId === DonationPageType.DP ? 'donate' : 'contact';
    const splitPath = pathname.split('/');
    const route = splitPath[splitPath.length - 1] || defaultRouteName;

    if (!donationPageDetails) {
      if (route !== defaultRouteName) {
        history.push(
          `/${
            donationPageTypeId === DonationPageType.DP
              ? 'donation'
              : 'donationCaller'
          }/${friendlyUrl}/`
        );
      }

      if (!isLaoding) {
        dispatch(fetchDonationPageDetails(friendlyUrl, defaultDesignation));
      }
    }
  }, [dispatch, friendlyUrl, donationPageDetails]);

  React.useEffect(() => {
    if (
      donationPageDetails &&
      donationPageDetails.languageCode &&
      donationPageDetails.languageCode !== i18n.language
    ) {
      i18n.changeLanguage(donationPageDetails.languageCode);
    }

    if (donationPageDetails) {
      if (donationPageDetails.showPaymentNotice) {
        dispatch(
          setPaymentNotice(
            !!(
              donationPageDetails.paymentNotice &&
              donationPageDetails.showPaymentNotice &&
              donationPageDetails.setAcceptNotice
            )
          )
        );
      }
      setPageType(donationPageDetails.donationPageTypeId);
      if (donationPageDetails.signup) {
        dispatch(setContactSignUp(donationPageDetails.setSignUp));
      }

      if (donationPageDetails.showCustomQuestions) {
        const pageKind = PageKind[PageKind.Donation];
        dispatch(
          fetchCustomQuestions(donationPageDetails.donationPageId, pageKind)
        );
      }
    }
  }, [donationPageDetails]);

  React.useEffect(() => {
    if (clientCode && donationPageDetails) {
      const clientTheme = loadTheme({
        clientCode,
        pageKind: PageKind.Donation,
        pageType: donationPageType,
        backgrounds: donationPageDetails
          ? donationPageDetails.backgroundImageCollection
          : {},
      });
      setTheme(clientTheme);
      if (gtmId) {
        const tagPageArgs = {
          dataLayer: {
            event: 'mcrm_page',
            page: {
              client_code: clientCode,
              kind: 'donation page',
              type: DonationPageType[donationPageType],
              id: donationPageDetails.donationPageId,
              language_code: donationPageDetails.languageCode,
              url_identifier: donationPageDetails.friendlyUrl,
            },
          },
          dataLayerName: 'MCRMDataLayer',
        };
        TagManager.dataLayer(tagPageArgs);
      }
    }
  }, [clientCode, donationPageDetails]);

  const handleResetProcessOnClick = () => {
    if (selectedNewGift) {
      const selectedNewGift = false;
      dispatch(setSelectedNewGift(selectedNewGift));
      history.push('.');
    }
  };

  React.useEffect(() => {
    if (
      donationPageDetails &&
      donationPageDetails.donationPageTypeId === DonationPageType.OCP
    ) {
      setIsOCPGate(!contactDetails && !selectedNewGift);
    }
  }, [contactDetails, selectedNewGift, donationPageDetails]);

  const currencySymbol =
    donationPageDetails &&
    donationPageDetails.transactionCurrency &&
    donationPageDetails.transactionCurrency.currencySymbol
      ? donationPageDetails.transactionCurrency.currencySymbol
      : '$';
  const isoCurrencyCode =
    donationPageDetails &&
    donationPageDetails.transactionCurrency &&
    donationPageDetails.transactionCurrency.isoCurrencyCode
      ? donationPageDetails.transactionCurrency.isoCurrencyCode
      : 'CAD';
  const languageCode =
    (donationPageDetails && donationPageDetails.languageCode) || 'en-CA';

  const transactionCurrency = {
    currencySymbol,
    isoCurrencyCode,
    languageCode,
  };
  const isOCP = donationPageType === DonationPageType.OCP;
  const classes = useStyles({
    isOCP,
  });

  return (
    <ThemeProvider theme={theme}>
      <TransactionCurrency.Provider value={transactionCurrency}>
        <main className={classes.main}>
          {donationPageType === DonationPageType.DP &&
            (donationPageDetails ? (
              <DonationLayout
                pageDetails={donationPageDetails}
                friendlyUrl={friendlyUrl}
                donationPageTypeId={donationPageTypeId}
              />
            ) : (
              <DonationLayoutSkeleton />
            ))}
          {donationPageType === DonationPageType.OCP && donationPageDetails && (
            <OCPLayout
              pageDetails={donationPageDetails}
              friendlyUrl={friendlyUrl}
              isOCPGate={isOCPGate}
              donationPageTypeId={donationPageTypeId}
              handleResetProcessOnClick={handleResetProcessOnClick}
            />
          )}
        </main>
      </TransactionCurrency.Provider>
    </ThemeProvider>
  );
};

export default React.memo(DonationModule);
