/* eslint-disable no-unused-vars */
import {
  PaymentType,
  PageKind,
  CustomerType,
  PreferenceQuestionFormatType,
  YesNo,
} from '@root/enums';

export type {
  PageDetails,
  TransactionCurrency,
  TermsOfReference,
} from '@root/interfaces';

declare global {
  interface Navigator {
    connection?: any | null;
    deviceMemory?: number | null;
  }
}

export interface AppState {
  clientCode: string | null;
  clientHomepage: string | null;
  contactDetails: ContactDetails | null;
  stripeIntent: StripeIntent | null;
  donationAmtAndFreq: DonationAmtAndFreq | null;
  addressSuggestions?: [Object];
  selectedPackageId: string | null;
  coverCosts: boolean | null;
  contactSignUp: boolean;
  acceptNotice: boolean;
  isLoading: boolean;
  isLoadingContactDetails: boolean;
  isLoadingPaymentMethods: boolean;
  updateCompleted: boolean;
  error: string | null;
  contactSearchError: string | null;
  selectedMembership: SelectedMembership;
  additionalInformation: AdditionalInformation;
  // preferenceQuestions: CustomQuestionsCategories;
  preferenceQuestions: CustomQuestions;
  selectedNewGift: boolean;
  paymentMethods: PaymentMethod[];
  cancellationStatus: boolean;
  giftAidClaimAmount: number;
  giftAidCheckbox: boolean;
  creditCardNo: string | null;
  expiryMM: string | null;
  expiryYY: string | null;
  cvcNo: string | null;
  membershipGroups?: any;
  preferenceQuestionsArray?: any;
  sentryKey?: string | null;
  stripeIntentResult?: any;
  verificationAddressResult?: any;
  gtmId?: string | null;
  errorLogResult: any;
}

export interface ContactDetails {
  contactId: string;
  constituentNumber?: string;
  transactionCurrencyId?: string;
  organizationName?: string;
  employer?: string;
  firstName: string;
  lastName: string;
  salutation?: string;
  address1Line1?: string;
  address1Line2?: string;
  address1Line3?: string;
  address1City?: string;
  address1StateOrProvince?: string;
  address1County?: string;
  address1Country?: string;
  address1PostalCode?: string;
  emailAddress1?: string;
  telephone1?: string;
  lifetimeGiving?: number;
  thisYearGiving?: number;
  lastTransactionDate?: Date;
  lastTransactionAmount?: number;
  lastTransactionStatus?: StatusCode;
  membership?: Membership;
  dedicate?: string;
  employerMatchesDonationCheckbox?: boolean;
  nameOfHonouree?: string;
  recipientEmail?: string;
  specialMessage?: string;
  tribute?: string;
  tributeAddressee?: string;
  tributeLine1?: string;
  tributeLine2?: string;
  tributeCity?: string;
  tributeStateOrProvince?: string;
  tributePostalCode?: string;
  tributeCountry?: string;
  tributeOptionsCode?: string | number | null;
  ackCheckbox?: boolean;
  anonymousCheckbox?: boolean;
  creditCard?: PaymentMethod;
  next?: string;
  canadaPostId?: string;
  isCompanyGift?: string;
}

export interface StripeIntent {
  paymentProcessorId: string;
  transactionCurrencyId: string;
  paymentIntentId?: string;
  amount: number;
  pageId: string;
  pageKind: PageKind;
  customerDetails: CustomerDetails;
  address?: CustomerAddress;
  paymentType: PaymentType;
  paymentMethods?: string;
  recaptchaToken?: string;
}

export interface CustomerDetails {
  customerName?: string;
  customerEmail?: string;
  customerPhone?: string;
  customerId?: string;
  customerType?: CustomerType;
}

export interface CustomerAddress {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
}

// To use when categories are added to custom Questions
export interface CustomQuestionsCategories {
  [questionCategoryId: string]: CustomQuestionsCategory;
}

export interface CustomQuestionsCategory {
  name: string;
  questions: Questions;
}

export interface CustomQuestions {
  questions: Questions;
}

export interface Questions {
  [questionId: string]: Question;
}

export interface Question {
  question: string;
  preferenceQuestionId?: string;
  preferenceQuestionFormatCode: PreferenceQuestionFormatType;
  checkboxQuestionOption: CheckboxQuestionOption;
  labelSet: any[];
  isRequired: boolean;
  yesOrNo: YesNo;
  response?: string;
}

export interface CheckboxQuestionOption {
  preferenceQuestionId?: string;
  presentedText?: string;
  communicationApplicationCode?: CommunicationApplicationCode;
  labelSet?: LabelSet;
  response?: string;
}

export interface LabelSet {
  positiveLabel?: string;
  negativeLabel?: string;
}

export interface PaymentMethod {
  paymentMethodId: string;
  name: string;
  ccLast4: string;
  ccExpMmYy: string;
  ccBrandCode: CreditCardType;
  type: PaymentMethodsType;
}
export interface PaymentMethodPost {
  contactId: string;
  donationPageId: string;
  paymentScheduleId?: string;
  isoCurrencyCode: string;
  creditCardNo: string;
  expMM: string;
  expYY: string;
  cvc: string;
}

export enum PaymentMethodsType {
  CreditCard = 703650000,
  Bank,
}

export enum CreditCardType {
  Visa = 703650000,
  VisaDebit = 703650002,
  MasterCard = 703650001,
  MasterCardDebit = 703650003,
  AmericanExpress = 703650004,
  Discovery = 703650008,
  MC = 703650001,
  Amex = 703650004,
}

export interface DonationAmtAndFreq {
  donationAmount: number;
  givingFrequency: string;
}

export enum GivingFrequency {
  SingleOnly = 703650000,
  s = 703650000,
  SingleDefaultAllowMonthly = 703650001,
  sm = 703650001,
  MonthlyDefaultAllowSingle = 703650002,
  ms = 703650002,
  MonthlyOnly = 703650003,
  m = 703650003,
  SingleDefaultAllowMonthlyAllowAnnual = 703650004,
  sma = 703650004,
  MonthlyDefaultAllowSingleAllowAnnual = 703650005,
  msa = 703650005,
  AnnualDefaultAllowSingleAllowMonthly = 703650006,
  asm = 703650006,
  MonthlyDefaultAllowAnnual = 703650007,
  ma = 703650007,
  AnnualDefaultAllowMonthly = 703650008,
  am = 703650008,
  AnnualOnly = 703650009,
  a = 703650009,
}

export enum CancellationCode {
  FeltPressure = 703650000,
  NoLongerAfford = 703650001,
  UnderMinimumWage = 703650003,
  SpouseObjected = 703650004,
  ChangedMind = 703650005,
  IntendedShortTerm = 703650006,
  IntendedOneTime = 703650007,
  Deceased = 703650008,
  Misunderstood = 703650002,
  OtherCharities = 703650009,
  PaymentDeclined = 703650010,
  Complaint = 703650011,
  AccountClosed = 703650012,
  AlreadyDonating = 703650013,
  NoReason = 703650014,
}

export enum CommunicationApplicationCode {
  Email = 703650000,
  Print,
  Phone,
}

export interface Membership {
  membershidId: string;
  membershidCategoryId?: string;
  startDate?: Date;
  endDate?: Date;
  name: string;
}

export interface SelectedMembership {
  membershipAmount?: number;
  membershipAmountTax?: number;
  membershipBaseAmount?: number;
  membershipId?: string;
  membership?: boolean;
  identifier?: string;
  includeMembership?: boolean;
}

export interface AdditionalInformation {
  transactionDescription?: string;
}

export interface PaymentDetails {
  creditCardNo: string;
  expMM: string;
  expYY: string;
  cvc: string;
  coverCostsCheckbox: boolean;
  signUpCheckbox: boolean;
  acceptNoticeCheckbox: boolean;
}

export enum StatusCode {
  Completed = 703650000,
  Terminated = 703650001, // = cancelled
  Failed = 703650003,
  TransactionRefund = 703650004, // = refund
  InProgress = 703650005,
}

export interface ApplicationInsightsLog {
  pageId: string;
  message: string;
}
// export enum TributeOptionsCode {
//   EmailOnly = 703650000,
//   MailOnly = 703650001,
//   EmailandMail = 703650002,
// }
